var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rn_my_wrap"},[_c('div',{staticClass:"rn_my_tit_2"},[_vm._v(" 비밀번호 찾기"),_c('router-link',{attrs:{"to":"/member/login"}},[_c('img',{attrs:{"src":require("@/assets/images/sub/back_ico.png"),"alt":""}})])],1),_c('div',{staticClass:"rn_my_empty_30"}),_c('ValidationObserver',{ref:"obServer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.getFindAuthNumber.apply(null, arguments)}}},[_c('div',{staticClass:"search_idpw"},[_c('h3',[_vm._v("찾으려는 비밀번호의 아이디를 입력해주세요.")]),_c('div',{staticClass:"input top"},[_c('div',{staticClass:"box"},[_c('h4',[_vm._v("램 아이디")]),_c('div',[_c('ValidationProvider',{ref:"userid",attrs:{"rules":{
                  required: true,
                },"name":"아이디"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userid),expression:"userid"}],staticClass:"w_max",attrs:{"type":"text","name":"","id":"","maxlength":"20","placeholder":"램 아이디"},domProps:{"value":(_vm.userid)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userid=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)])]),_c('h3',[_vm._v("아래 절차를 따라 휴개전화 인증을 해주세요.")]),_c('div',{staticClass:"explain"},[_c('h4',[_vm._v("◎ 회원정보에 등록된 휴대전화로 인증")]),_c('p',[_vm._v(" 아래에 회원가입 시 또는 이후 등록/수정한 이름 및 휴대전화 번호와 동일한 정보를 입력해 주세요. 등록되어 있는 정보와 일치해야 인증이 됩니다. ")])]),_c('div',{staticClass:"input"},[_c('div',{staticClass:"box"},[_c('h4',[_vm._v("이름")]),_c('div',[_c('ValidationProvider',{ref:"name",attrs:{"rules":{
                  required: true,
                },"name":"이름"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userName),expression:"userName"}],staticClass:"w_max",attrs:{"type":"text","name":"","id":"","maxlength":"20","placeholder":"이름"},domProps:{"value":(_vm.userName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userName=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"box"},[_c('h4',[_vm._v("휴대전화 번호")]),_c('ValidationProvider',{ref:"phone",attrs:{"rules":{
                required: true,
                cellphone: true,
              },"name":"휴대폰번호"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"phone"},[_c('span',[_vm._v("+82")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cellphoneCk),expression:"cellphoneCk"}],attrs:{"type":"text","name":"","id":"","maxlength":"11","placeholder":"휴대전화 번호"},domProps:{"value":(_vm.cellphoneCk)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getFindAuthNumber.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.cellphoneCk=$event.target.value}}}),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.getFindAuthNumber.apply(null, arguments)}}},[_vm._v("인증번호 전송")])],1),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"box"},[_c('h4',[_vm._v("인증번호 입력")]),_c('ValidationProvider',{ref:"authNum",attrs:{"rules":{
                required: true,
                digits: 6,
              },"name":"인증번호"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{style:({ position: 'relative' })},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.authNumberCk),expression:"authNumberCk"}],staticClass:"w_max",attrs:{"type":"text","name":"","id":"","maxlength":"6","placeholder":"인증번호 입력"},domProps:{"value":(_vm.authNumberCk)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.checkUserData.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.authNumberCk=$event.target.value}}}),(_vm.isAuthNumber)?_c('div',{staticClass:"timer"},[_c('span',{ref:"timer_min"},[_vm._v("2")]),_c('span',[_vm._v(":")]),_c('span',{ref:"timer_sec"},[_vm._v("59")])]):_vm._e()]),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"top"},[_vm._v(" ※ 인증번호가 오지 않나요?"),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.open($event)}}},[_vm._v("∨")])],1),_c('ul',{staticStyle:{"display":"none"}},[_c('li',[_vm._v(" "+_vm._s(_vm.rammPhone)+"이 스팸 문자로 등록되어 있지 않은지 확인해주세요. 스팸 문자로 등록되어 있다면 스팸 등록을 해제해 주시고, 스팸문자로 등록되어 있지 않다면 [인증번호 전송]을 다시 한번 눌러주세요. ")])])])]),_c('div',{staticClass:"btn"},[_c('router-link',{staticClass:"yellow",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.checkUserData.apply(null, arguments)}}},[_vm._v("확인")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }