<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      비밀번호 찾기<router-link to="/member/login"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <ValidationObserver ref="obServer">
      <form @submit.prevent="getFindAuthNumber">
        <div class="search_idpw">
          <h3>찾으려는 비밀번호의 아이디를 입력해주세요.</h3>
          <div class="input top">
            <div class="box">
              <h4>램 아이디</h4>
              <div>
                <ValidationProvider
                  ref="userid"
                  :rules="{
                    required: true,
                  }"
                  v-slot="{ errors }"
                  name="아이디"
                >
                  <input
                    type="text"
                    name=""
                    id=""
                    v-model="userid"
                    maxlength="20"
                    class="w_max"
                    placeholder="램 아이디"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <h3>아래 절차를 따라 휴개전화 인증을 해주세요.</h3>
          <div class="explain">
            <h4>◎ 회원정보에 등록된 휴대전화로 인증</h4>
            <p>
              아래에 회원가입 시 또는 이후 등록/수정한 이름 및 휴대전화 번호와
              동일한 정보를 입력해 주세요. 등록되어 있는 정보와 일치해야 인증이
              됩니다.
            </p>
          </div>

          <div class="input">
            <div class="box">
              <h4>이름</h4>
              <div>
                <ValidationProvider
                  ref="name"
                  :rules="{
                    required: true,
                  }"
                  v-slot="{ errors }"
                  name="이름"
                >
                  <input
                    type="text"
                    name=""
                    id=""
                    v-model="userName"
                    maxlength="20"
                    class="w_max"
                    placeholder="이름"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>
            <div class="box">
              <h4>휴대전화 번호</h4>
              <ValidationProvider
                ref="phone"
                :rules="{
                  required: true,
                  cellphone: true,
                }"
                v-slot="{ errors }"
                name="휴대폰번호"
              >
                <div class="phone">
                  <span>+82</span>

                  <input
                    type="text"
                    name=""
                    id=""
                    v-model="cellphoneCk"
                    maxlength="11"
                    @keypress.enter="getFindAuthNumber"
                    placeholder="휴대전화 번호"
                  />

                  <router-link to="" @click.native="getFindAuthNumber"
                    >인증번호 전송</router-link
                  >
                </div>
                <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="box">
              <h4>인증번호 입력</h4>
              <ValidationProvider
                ref="authNum"
                :rules="{
                  required: true,
                  digits: 6,
                }"
                v-slot="{ errors }"
                name="인증번호"
              >
                <div :style="{ position: 'relative' }">
                  <input
                    type="text"
                    name=""
                    id=""
                    v-model="authNumberCk"
                    maxlength="6"
                    @keypress.enter="checkUserData"
                    class="w_max"
                    placeholder="인증번호 입력"
                  />
                  <div class="timer" v-if="isAuthNumber">
                    <span ref="timer_min">2</span>
                    <span>:</span>
                    <span ref="timer_sec">59</span>
                  </div>
                </div>
                <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <div class="info">
            <div class="box">
              <div class="top">
                ※ 인증번호가 오지 않나요?<router-link
                  to=""
                  @click.native="open($event)"
                  >∨</router-link
                >
              </div>
              <ul style="display: none">
                <li>
                  {{ rammPhone }}이 스팸 문자로 등록되어 있지 않은지
                  확인해주세요. 스팸 문자로 등록되어 있다면 스팸 등록을 해제해
                  주시고, 스팸문자로 등록되어 있지 않다면 [인증번호 전송]을 다시
                  한번 눌러주세요.
                </li>
              </ul>
            </div>
          </div>

          <div class="btn">
            <router-link to="" class="yellow" @click.native="checkUserData"
              >확인</router-link
            >
          </div>
        </div>
      </form>
    </ValidationObserver>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      userid: "",
      userName: "",
      cellphone: "",
      authNumber: "",
      mode: "password",
      rammPhone: process.env.VUE_APP_RAMM_PHONE,
    };
  },
  created() {
    this.$store.commit("member/updateCellphoneReset");
  },
  computed: {
    ...mapState("member", [
      "msg",
      "result",
      "old_cellphone",
      "tryCnt",
      "limitCnt",
      "isCheckPhone",
    ]),
    cellphoneCk: {
      get() {
        return this.cellphone;
      },
      set(cellphone) {
        this.cellphone = cellphone.replace(/[^0-9]/g, "");
      },
    },
    authNumberCk: {
      get() {
        return this.authNumber;
      },
      set(authNumber) {
        this.authNumber = authNumber.replace(/[^0-9]/g, "");
      },
    },
    isAuthNumber: {
      get() {
        return this.$store.state.member.isAuthNumber;
      },
      set(isAuthNumber) {
        this.$store.commit("member/updateUserInfo", {
          isAuthNumber,
        });
      },
    },
  },
  methods: {
    open(e) {
      const ulEl = e.target.closest(".box").querySelector("ul");
      if (ulEl.style.display == "none") {
        e.target.textContent = "∧";
        ulEl.style.display = "block";
      } else {
        e.target.textContent = "∨";
        ulEl.style.display = "none";
      }
    },
    async checkUserData() {
      await this.$refs.userid.validate();
      await this.$refs.name.validate();
      await this.$refs.phone.validate();
      await this.$refs.authNum.validate();
      if (this.userid.replace(/\s/g, "").length <= 0) {
        return false;
      }
      if (this.userName.replace(/\s/g, "").length <= 0) {
        return false;
      }
      if (this.cellphone.replace(/\s/g, "").length <= 0) {
        return false;
      }
      if (this.authNumber.replace(/\s/g, "").length <= 0) {
        return false;
      }
      await this.$store.dispatch("member/authFindPhoneConfirm", {
        cellphone: this.cellphone && this.cellphone.trim(),
        authNumber: this.authNumber && this.authNumber.trim(),
        user_name: this.userName,
        userid: this.userid,
        mode: this.mode,
      });

      if (this.isCheckPhone) {
        this.$router.push("/member/findpw_result");
      } else {
        this.$toast.default(this.msg);
      }
    },
    async getFindAuthNumber() {
      await this.$refs.userid.validate();
      await this.$refs.name.validate();
      await this.$refs.phone.validate();
      const phoenReg = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
      if (this.userid.replace(/\s/g, "").length <= 0) {
        return false;
      }
      if (this.userName.replace(/\s/g, "").length <= 0) {
        return false;
      }
      if (!phoenReg.test(this.cellphone)) {
        return false;
      }
      if (this.isAuthNumber) {
        if (this.old_cellphone === this.cellphone) {
          this.$toast.default(
            `동일한 번호로 이미 인증번호를 발송 했습니다.
          인증번호 요청시도(${this.tryCnt}/${this.limitCnt})`
          );
          return false;
        }
      }
      this.isAuthNumber = false;
      clearInterval(this.timer);
      this.isTimer = false;

      await this.$store.dispatch("member/getFindAuthNumber", {
        cellphone: this.cellphone,
        user_name: this.userName,
        userid: this.userid,
        mode: this.mode,
      });
      if (this.isAuthNumber) {
        if (this.isTimer === false) {
          this.startTimer();
        }
        this.$toast.default(this.msg);
      } else {
        this.$toast.default(this.msg);
      }
    },
    startTimer() {
      this.isTimer = true;
      const minEl = this.$refs.timer_min;
      const secEl = this.$refs.timer_sec;
      let sec = 59;
      let min = 2;
      let sec_str = "";
      this.timer = setInterval(() => {
        if (sec == 0) {
          sec = 59;
          if (min != 0) {
            min = min - 1;
          }
        } else {
          sec = sec - 1;
          sec_str = sec.toString();
          if (sec < 10) {
            sec_str = `0${sec.toString()}`;
          }
        }
        minEl.textContent = min;
        secEl.textContent = sec_str;
        if (min == 0 && sec == 0) {
          clearInterval(this.timer);
          this.isAuthNumber = false;
        }
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.timer {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: $info-txt;
  font-size: 14px;
  width: auto !important;
  margin: auto !important;
}
#timer_min {
  float: none;
}
#timer_sec {
  float: none;
}
</style>
